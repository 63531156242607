import { cookieDomain } from '../utils/domain';

export interface PortalConfig {
  cookieDomain?: string;
  host: string;
  loginUrl: string;
  homeUrl: string;
}
const dev: PortalConfig = {
  cookieDomain: cookieDomain,
  host: 'dev-my.simulation.cloud',
  loginUrl: `${window.location.origin}/login`,
  homeUrl: 'https://develop.d2dhrbzbpo6ev1.amplifyapp.com',
};
const uat: PortalConfig = {
  ...dev,
  host: 'my.uat-simulation.cloud',
  // loginUrl: 'https://uat-my.simulation.cloud/login',
  homeUrl: 'https://www.simulation.cloud',
};
const pentest: PortalConfig = {
  ...uat,
  host: 'pentest-my.uat-simulation.cloud',
  // loginUrl: 'https://uat-my.simulation.cloud/login',
  homeUrl: 'https://www.simulation.cloud',
};
const prod: PortalConfig = {
  ...dev,
  host: 'my.simulation.cloud',
  // loginUrl: 'https://my.simulation.cloud/login',
  homeUrl: 'https://www.simulation.cloud',
};

export function getPortalConfig(): PortalConfig {
  const BUILD_ENV = process.env.REACT_APP_BUILD_ENV;
  if (BUILD_ENV === 'prod') {
    return prod;
  } else if (BUILD_ENV === 'uat') {
    return uat;
  } else if (BUILD_ENV === 'pentest') {
    return pentest;
  }
  return dev;
}

export default getPortalConfig();
