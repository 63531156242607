export interface ProviderConfigMap {
  anduril: ProviderConfig;
}
export interface ProviderConfig {
  redirectUrl: string;
  loginUrl: string;
}
const dev: ProviderConfigMap = {
  anduril: {
    redirectUrl: 'https://anduril-flow360.dev-simulation.cloud',
    loginUrl: `https://sso.dev-simulation.cloud/oauth2/authorize?client_id=3juubqouhhf354ai4b79tcal2j&response_type=code&scope=email+openid+profile&redirect_uri=https://anduril-my.dev-simulation.cloud/callback/flow360/anduril`,
  },
};
const uat: ProviderConfigMap = {
  anduril: {
    redirectUrl: 'https://anduril-flow360.uat-simulation.cloud',
    loginUrl: `https://sso.uat-simulation.cloud/oauth2/authorize?client_id=24oodmcut9qcrbiij0i274cnrf&response_type=code&scope=email+openid+profile&redirect_uri=https://anduril-my.uat-simulation.cloud/callback/flow360/anduril`,
  },
};
const pentest: ProviderConfigMap = { ...uat };
const prod: ProviderConfigMap = {
  anduril: {
    redirectUrl: 'https://anduril-flow360.simulation.cloud',
    loginUrl: `https://sso.simulation.cloud/oauth2/authorize?client_id=41ut0khprdcacuc5cfedl4s3hh&response_type=code&scope=email+openid+profile&redirect_uri=https://anduril-my.simulation.cloud/callback/flow360/anduril`,
  },
};

export function getProviderConfigMap(): ProviderConfigMap {
  const BUILD_ENV = process.env.REACT_APP_BUILD_ENV;
  if (BUILD_ENV === 'prod') {
    return prod;
  } else if (BUILD_ENV === 'uat') {
    return uat;
  } else if (BUILD_ENV === 'pentest') {
    return pentest;
  }
  return dev;
}
export function getProviderConfig(provider: string): ProviderConfig | null {
  const providerConfigMap = getProviderConfigMap();
  return providerConfigMap[provider as keyof typeof providerConfigMap] ?? null;
}
