import { isFromFlow360, isFromTidy3d } from '../utils/util';

export interface AppConfigMap {
  flow360: AppConfig;
  tidy3d: AppConfig;
}
export interface AppConfig {
  redirectUrl: string;
  googleLogin: string;
  googleLogout: string;
  microsoftLogin: string;
  appleLogin: string;
  anduril: {
    redirectUrl: string;
    loginUrl: string;
  };
}
const dev: AppConfigMap = {
  flow360: {
    redirectUrl: 'https://flow360.dev-simulation.cloud',
    googleLogin: `https://sso.dev-simulation.cloud/oauth2/authorize?identity_provider=Google&response_type=CODE&client_id=757hm5uf56qos64jo8ml6asd0g&scope=email%20openid%20profile&prompt=select_account&redirect_uri=https://my.dev-simulation.cloud/callback/flow360`,
    googleLogout: `https://sso.dev-simulation.cloud/logout?client_id=757hm5uf56qos64jo8ml6asd0g&logout_uri=`,
    appleLogin: '',
    microsoftLogin: '',
    anduril: {
      redirectUrl: 'https://anduril-flow360.dev-simulation.cloud',
      loginUrl: `https://sso.dev-simulation.cloud/oauth2/authorize?client_id=3juubqouhhf354ai4b79tcal2j&response_type=code&scope=email+openid+profile&redirect_uri=https://anduril-my.dev-simulation.cloud/callback/flow360/anduril`,
    },
  },
  tidy3d: {
    redirectUrl: 'https://tidy3d.dev-simulation.cloud',
    googleLogin: `https://sso.dev-simulation.cloud/oauth2/authorize?identity_provider=Google&response_type=CODE&client_id=1l5st1cgo1iee522neqbn5hbc9&scope=email%20openid%20profile&prompt=select_account&redirect_uri=https://my.dev-simulation.cloud/callback/tidy3d`,
    googleLogout: `https://sso.dev-simulation.cloud/logout?client_id=1l5st1cgo1iee522neqbn5hbc9&logout_uri=`,
    appleLogin: `https://sso.dev-simulation.cloud/oauth2/authorize?identity_provider=SignInWithApple&client_id=1l5st1cgo1iee522neqbn5hbc9&response_type=code&scope=email+openid+profile&prompt=select_account&redirect_uri=https://my.dev-simulation.cloud/callback/tidy3d`,
    microsoftLogin:
      'https://sso.dev-simulation.cloud/oauth2/authorize?identity_provider=Microsoft&response_type=CODE&client_id=1l5st1cgo1iee522neqbn5hbc9&scope=email%20openid%20profile&prompt=select_account&redirect_uri=https://my.dev-simulation.cloud/callback/tidy3d',
    anduril: {
      redirectUrl: '',
      loginUrl: '',
    },
  },
};
const uat: AppConfigMap = {
  flow360: {
    redirectUrl: 'https://flow360.uat-simulation.cloud',
    googleLogin: `https://sso.uat-simulation.cloud/oauth2/authorize?identity_provider=Google&response_type=CODE&client_id=1qn1ohmjth9eed3gpci5huq4f4&scope=email%20openid%20profile&prompt=select_account&redirect_uri=https://my.uat-simulation.cloud/callback/flow360`,
    googleLogout: `https://sso.uat-simulation.cloud/logout?client_id=1qn1ohmjth9eed3gpci5huq4f4&logout_uri=`,
    appleLogin: '',
    microsoftLogin: '',
    anduril: {
      redirectUrl: 'https://anduril-flow360.uat-simulation.cloud',
      loginUrl: `https://sso.uat-simulation.cloud/oauth2/authorize?client_id=24oodmcut9qcrbiij0i274cnrf&response_type=code&scope=email+openid+profile&redirect_uri=https://anduril-my.uat-simulation.cloud/callback/flow360/anduril`,
    },
  },
  tidy3d: {
    redirectUrl: 'https://tidy3d.uat-simulation.cloud',
    googleLogin: `https://sso.uat-simulation.cloud/oauth2/authorize?identity_provider=Google&response_type=CODE&client_id=6qq60ahpvp5ooglmofoffb5osi&scope=email%20openid%20profile&prompt=select_account&redirect_uri=https://my.uat-simulation.cloud/callback/tidy3d`,
    googleLogout: `https://sso.uat-simulation.cloud/logout?client_id=6qq60ahpvp5ooglmofoffb5osi&logout_uri=`,
    appleLogin: `https://sso.uat-simulation.cloud/oauth2/authorize?identity_provider=SignInWithApple&client_id=6qq60ahpvp5ooglmofoffb5osi&response_type=code&scope=email+openid+profile&prompt=select_account&redirect_uri=https://my.uat-simulation.cloud/callback/tidy3d`,
    microsoftLogin: `https://sso.uat-simulation.cloud/oauth2/authorize?identity_provider=Microsoft&response_type=CODE&client_id=6qq60ahpvp5ooglmofoffb5osi&scope=email%20openid%20profile&prompt=select_account&redirect_uri=https://my.uat-simulation.cloud/callback/tidy3d`,
    anduril: {
      redirectUrl: '',
      loginUrl: '',
    },
  },
};
const pentest = {
  flow360: {
    redirectUrl: 'https://pentest-flow360.uat-simulation.cloud',
    googleLogin: `https://sso.uat-simulation.cloud/oauth2/authorize?identity_provider=Google&response_type=CODE&client_id=1qn1ohmjth9eed3gpci5huq4f4&scope=email%20openid%20profile&prompt=select_account&redirect_uri=https://pentest-my.uat-simulation.cloud/callback/flow360`,
    googleLogout: `https://sso.uat-simulation.cloud/logout?client_id=1qn1ohmjth9eed3gpci5huq4f4&logout_uri=`,
    appleLogin: '',
    microsoftLogin: '',
    anduril: {
      redirectUrl: 'https://anduril-flow360.uat-simulation.cloud',
      loginUrl: `https://sso.uat-simulation.cloud/oauth2/authorize?client_id=24oodmcut9qcrbiij0i274cnrf&response_type=code&scope=email+openid+profile&redirect_uri=https://anduril-my.uat-simulation.cloud/callback/flow360/anduril`,
    },
  },
  tidy3d: {
    redirectUrl: 'https://pentest-tidy3d.uat-simulation.cloud',
    googleLogin: `https://sso.uat-simulation.cloud/oauth2/authorize?identity_provider=Google&response_type=CODE&client_id=6qq60ahpvp5ooglmofoffb5osi&scope=email%20openid%20profile&prompt=select_account&redirect_uri=https://pentest-my.uat-simulation.cloud/callback/tidy3d`,
    googleLogout: `https://sso.uat-simulation.cloud/logout?client_id=6qq60ahpvp5ooglmofoffb5osi&logout_uri=`,
    appleLogin: `https://sso.uat-simulation.cloud/oauth2/authorize?identity_provider=SignInWithApple&client_id=6qq60ahpvp5ooglmofoffb5osi&response_type=code&scope=email+openid+profile&prompt=select_account&redirect_uri=https://pentest-my.uat-simulation.cloud/callback/tidy3d`,
    microsoftLogin: `https://sso.uat-simulation.cloud/oauth2/authorize?identity_provider=Microsoft&response_type=CODE&client_id=6qq60ahpvp5ooglmofoffb5osi&scope=email%20openid%20profile&prompt=select_account&redirect_uri=https://pentest-my.uat-simulation.cloud/callback/tidy3d`,
    anduril: {
      redirectUrl: '',
      loginUrl: '',
    },
  },
};
const prod: AppConfigMap = {
  flow360: {
    redirectUrl: 'https://flow360.simulation.cloud',
    googleLogin: `https://sso.simulation.cloud/oauth2/authorize?identity_provider=Google&response_type=CODE&client_id=2oltenfma8olqqb9ecpsgl92lb&scope=email%20openid%20profile&prompt=select_account&redirect_uri=https://my.simulation.cloud/callback/flow360`,
    googleLogout: `https://sso.simulation.cloud/logout?client_id=2oltenfma8olqqb9ecpsgl92lb&logout_uri=`,
    microsoftLogin: '',
    appleLogin: '',
    anduril: {
      redirectUrl: 'https://anduril-flow360.simulation.cloud',
      loginUrl: `https://sso.simulation.cloud/oauth2/authorize?client_id=41ut0khprdcacuc5cfedl4s3hh&response_type=code&scope=email+openid+profile&redirect_uri=https://anduril-my.simulation.cloud/callback/flow360/anduril`,
    },
  },
  tidy3d: {
    redirectUrl: 'https://tidy3d.simulation.cloud',
    googleLogin:
      'https://sso.simulation.cloud/oauth2/authorize?identity_provider=Google&response_type=CODE&client_id=7v1iulgpsnuc85k8hlv32sn7qs&scope=email%20openid%20profile&prompt=select_account&redirect_uri=https://my.simulation.cloud/callback/tidy3d',
    googleLogout:
      'https://sso.simulation.cloud/logout?client_id=7v1iulgpsnuc85k8hlv32sn7qs&logout_uri=',
    appleLogin:
      'https://sso.simulation.cloud/oauth2/authorize?identity_provider=SignInWithApple&client_id=7v1iulgpsnuc85k8hlv32sn7qs&response_type=code&scope=email+openid+profile&prompt=select_account&redirect_uri=https://my.simulation.cloud/callback/tidy3d',
    microsoftLogin:
      'https://sso.simulation.cloud/oauth2/authorize?identity_provider=Microsoft&response_type=CODE&client_id=7v1iulgpsnuc85k8hlv32sn7qs&scope=email%20openid%20profile&prompt=select_account&redirect_uri=https://my.simulation.cloud/callback/tidy3d',
    anduril: {
      redirectUrl: '',
      loginUrl: '',
    },
  },
};

export function getAppConfigMap(): AppConfigMap {
  const BUILD_ENV = process.env.REACT_APP_BUILD_ENV;
  if (BUILD_ENV === 'prod') {
    return prod;
  } else if (BUILD_ENV === 'uat') {
    return uat;
  } else if (BUILD_ENV === 'pentest') {
    return pentest;
  }
  return dev;
}
export function getAppConfig(app: string): AppConfig | null {
  const appConfigMap = getAppConfigMap();
  return appConfigMap[app as keyof typeof appConfigMap];
}
export function getCurrentAppConfig(): AppConfig | null {
  const appConfigMap = getAppConfigMap();
  if (isFromFlow360()) {
    return appConfigMap.flow360;
  }
  if (isFromTidy3d()) {
    return appConfigMap.tidy3d;
  }
  return null;
}
